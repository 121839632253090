<template>
  <b-modal
    id="article-modal"
    ref="article-modal"
    @show="getPosition"
    @hide="resetModal"
    :title="type == 'edit' ? 'Cập nhật bài viết' : 'Tạo bài viết'"
    size="lg"
  >
    <b-container fluid class="p-0">
      <b-overlay :show="loading">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <b-row>
                <b-col style="min-height: 170px">
                  <label class="d-block text-center">Hình ảnh</label>
                  <label
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span style="font-size: 10px">Kích thước 184x108</span>
                  </label>
                  <div class="image-wrapper">
                    <v-file-input
                      hide-input
                      v-model="file"
                      accept="image/png, image/jpeg, image/bmp"
                      class="icon-edit"
                      @change="onUpload"
                      prepend-icon="mdi-pencil-outline"
                      truncate-length="15"
                    >
                    </v-file-input>
                    <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
                  </div>
                </b-col>
                <b-col style="min-height: 170px">
                  <label class="d-block text-center">Logo nhà tài trợ</label>
                  <label
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span style="font-size: 10px">Tỉ lệ 1:1 (140x140)</span>
                  </label>
                  <div class="image-wrapper">
                    <v-file-input
                      hide-input
                      v-model="filePartner"
                      accept="image/png, image/jpeg, image/bmp"
                      class="icon-edit"
                      @change="onUploadPartner"
                      prepend-icon="mdi-pencil-outline"
                      truncate-length="15"
                    >
                    </v-file-input>
                    <v-img :src="previewPartner" :aspect-ratio="1 / 1"></v-img>
                  </div>
                </b-col>
                <b-col style="min-height: 170px">
                  <label class="d-block text-center">Banner</label>
                  <label
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <span style="font-size: 10px">Kích thước 375x340</span>
                  </label>
                  <div class="image-wrapper">
                    <v-file-input
                      hide-input
                      v-model="fileBanner"
                      accept="image/png, image/jpeg, image/bmp"
                      class="icon-edit"
                      @change="onUploadBanner"
                      prepend-icon="mdi-pencil-outline"
                      truncate-length="15"
                    >
                    </v-file-input>
                    <v-img :src="previewBanner" :aspect-ratio="1 / 1"></v-img>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-input
                    label="Tên nhà tài trợ"
                    placeholder="Nhập tên nhà tài trợ"
                    name="partnerName"
                    :required="true"
                    :value.sync="form.partnerName"
                    data-vv-as="Tên nhà tài trợ"
                    v-validate="'required'"
                    :state="validateState('partnerName')"
                    :invalidFeedback="errors.first('partnerName')"
                  ></basic-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <basic-input
                label="Tiêu đề"
                placeholder="Tiêu đề"
                name="title"
                :required="true"
                :value.sync="form.title"
                data-vv-as="Tiêu đề"
                v-validate="'required'"
                :state="validateState('title')"
                :invalidFeedback="errors.first('title')"
              ></basic-input>
            </b-col>
          </b-row>
          <label class="d-block">Đường dẫn</label>
          <toggle-button
            color="#008479"
            v-model="form.enableLink"
            :sync="true"
          />
          <b-row v-if="form.enableLink">
            <b-col>
              <basic-input
                placeholder="Đường dẫn"
                name="link"
                :required="form.enableLink"
                :value.sync="form.link"
                data-vv-as="Đường dẫn"
                v-validate="form.enableLink ? 'required' : ''"
                :state="validateState('link')"
                :invalidFeedback="errors.first('link')"
              ></basic-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <basic-select
                label="Trạng thái"
                name="status"
                :required="true"
                v-validate="'required'"
                data-vv-as="Trạng thái"
                :state="validateState('status')"
                :invalidFeedback="errors.first('status')"
                :options="statusOptions"
                :value.sync="form.status"
                :solid="false"
                :allowEmpty="false"
              />
            </b-col>
            <b-col cols="6">
              <basic-select
                :required="true"
                label="Chủ đề"
                class="lesson-edit-page__dropdown-filter"
                placeholder="---Chọn chủ đề---"
                name="module"
                :solid="false"
                :value.sync="form.module"
                :options="moduleOptions"
                value-label="text"
                track-by="value"
                :state="validateState('module')"
                :invalidFeedback="errors.first('module')"
                v-validate="'required'"
                data-vv-as="Chủ đề"
                @update:value="selected($event, 'module', moduleOptions)"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <basic-select
                :required="!isOptionalMode"
                label="Cấp độ"
                class="lesson-edit-page__dropdown-filter"
                placeholder="---Chọn cấp độ---"
                name="level"
                :solid="false"
                :value.sync="form.level"
                :options="levelOptions"
                value-label="text"
                track-by="value"
                :state="validateState('level')"
                :invalidFeedback="errors.first('level')"
                v-validate="{ required: !isOptionalMode }"
                data-vv-as="Cấp độ"
                @update:value="selected($event, 'level', levelOptions)"
              />
            </b-col>
            <b-col cols="6">
              <label>Từ khóa</label>
              <multiselect
                :options="tagOptions"
                placeholder="Tìm kiếm hoặc tạo keyword mới"
                tag-placeholder="Thêm từ khóa"
                select-label=""
                selected-label="Đã chọn"
                deselect-label="Bỏ chọn"
                label="text"
                track-by="value"
                v-model="form.tags"
                multiple
                taggable
                @tag="onAddTag"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                class="required"
                :required="true"
                :state="validateState('position')"
                :invalidFeedback="errors.first('position')"
              >
                <template v-slot:label>
                  Vị trí hiển thị gợi ý:<span class="text-danger">*</span>
                </template>
                <b-form-checkbox-group
                  class="required"
                  name="position"
                  :required="true"
                  v-validate="'required'"
                  v-model="position"
                  :options="options"
                  :state="validateState('position')"
                  :invalidFeedback="errors.first('position')"
                  data-vv-as="Vị trí hiển thị gợi ý"
                ></b-form-checkbox-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <vue-editor
                id="editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="form.content"
              >
              </vue-editor>
            </b-col>
          </b-row>
        </form>
      </b-overlay>
    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handleCancel"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Huỷ
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          :disabled="loading"
          @click.stop="handelValidation"
          tabindex="0"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          {{ type == 'edit' ? 'Cập nhật' : 'Tạo' }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { VueEditor } from 'vue2-editor';

export default {
  name: 'Modal',
  components: {
    Multiselect,
    VueEditor,
  },
  props: {
    popupType: {
      type: String,
      default: 'create',
    },
    id: {
      type: String,
      default: null,
    },
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      position: null,
      file: null,
      filePartner: null,
      fileBanner: null,
      preview: null,
      previewPartner: null,
      previewBanner: null,
      form: {
        link: '',
        content: null,
        module: null,
        level: null,
        tags: [],
        enableLink: false,
      },
      loading: false,
      options: [],
      statusOptions: [
        { name: 'Inactive', id: 0 },
        { name: 'Active', id: 1 },
      ],
      moduleOptions: [],
      levelOptions: [],
      tagOptions: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.loadData(newVal);
        }
      },
    },
  },
  async mounted() {
    await this.loadPrepareData();
  },
  computed: {
    type() {
      return this.popupType;
    },
    isOptionalMode() {
      return this.form.type && this.form.type.value == 2;
    },
  },
  methods: {
    async loadPrepareData() {
      try {
        this.loading = true;
        let apiURI = `/LearningPost/PrepareCreateUpdateFormItem`;
        let resMetadata = await this.$api.get(apiURI);
        let dataMeta = resMetadata.data;
        this.levelOptions = this.getDropdownOptions(dataMeta.lessonLevels);
        this.moduleOptions = this.getDropdownOptions(dataMeta.lessonModules);
        this.tagOptions = this.getDropdownOptions(dataMeta.lessonTags);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    async getPosition() {
      this.loading = true;
      try {
        let { data } = await this.$api.get('LearningPost/Positions');
        this.options = data.map((e) => ({
          value: `${e.key}`,
          text: e.value,
        }));
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    getDropdownOptions(array) {
      return array.map((item) => {
        return {
          text: item.text,
          value: item.value,
        };
      });
    },
    getSelectedValueDropdown(array) {
      let selectedValue = array.find((el) => el.selected === true);
      return selectedValue
        ? {
            text: selectedValue.text,
            value: selectedValue.value,
          }
        : null;
    },
    selected(value, formProperty, optionsArr) {
      this.form[formProperty] = value
        ? optionsArr.find((el) => el.value == value)
        : null;
      if (this.form[formProperty]) {
        this.$validator.reset();
      }
    },
    async loadData(id) {
      try {
        let { data } = await this.$api.get(`LearningPost/${id}`);
        console.log(data);
        this.form = data;
        this.form.link = data.link && data.link != 'null' ? data.link : '';
        this.preview = data.imageUrl?.url;
        this.previewPartner = data.imagePartnerUrl?.url;
        this.previewBanner = data.imageBannerUrl?.url;
        this.form.status = this.statusOptions.find((e) => e.id == data.status);
        this.position = data.positions.map((e) => `${e.position}`);

        this.form.level = data.learningPostLevel
          ? {
              text: data.learningPostLevel.name,
              value: data.learningPostLevel.id,
            }
          : null;
        this.form.module = data.learningPostModule
          ? {
              text: data.learningPostModule.name,
              value: data.learningPostModule.id,
            }
          : null;

        this.form.tags = [];
        if (data.learningPostTagMappings) {
          let selectedTags = data.learningPostTagMappings.map((item) => {
            return {
              text: item.name,
              value: item.id,
            };
          });
          this.form.tags = selectedTags.length ? selectedTags : [];
        }
      } catch (error) {
        console.log('Loi roiiiii', error);
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      }
    },
    clear() {
      this.file = null;
      this.preview = null;
      this.form.images = null;

      this.filePartner = null;
      this.previewPartner = null;
      this.form.imagePartners = null;

      this.fileBanner = null;
      this.previewBanner = null;
      this.form.imageBanners = null;
    },
    onUpload() {
      this.preview = URL.createObjectURL(this.file);
    },
    onUploadPartner() {
      this.previewPartner = URL.createObjectURL(this.filePartner);
    },
    onUploadBanner() {
      this.previewBanner = URL.createObjectURL(this.fileBanner);
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      // upload file
      const { error, data } = await this.$store.dispatch(
        'context/UPLOAD_IMAGE',
        file,
      );
      if (error) {
        this.$toastr.s({
          title: 'Lỗi !',
          msg: error,
        });
        return null;
      }
      Editor.insertEmbed(cursorLocation, 'image', data.url);
      resetUploader();
    },
    onAddTag(tag) {
      let newTag = {
        text: tag,
        value: this.$uuid.v1(),
      };
      this.form.tags.push(newTag);
      this.tagOptions.push(newTag);
      this.newTagsOptions.push(newTag);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
    handelValidation() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }
        this.handleSubmit();
      });
    },
    async handleSubmit() {
      console.log('xxxxx');
      console.log(this.form.level?.value);
      this.loading = true;
      let positions = [...this.position];
      let payload = new FormData();
      console.log('this.form.module', this.form.module);
      let status =
        this.form.status.id !== undefined && this.form.status.id !== null
          ? this.form.status.id
          : this.form.status;
      payload.append('title', this.form.title);
      payload.append('link', this.form.link ?? '');
      payload.append('image', this.file);
      payload.append('imagePartner', this.filePartner);
      payload.append('imageBanner', this.fileBanner);
      payload.append('status', status);
      positions.forEach((e, index) => {
        payload.append(`positions[${index}].position`, e);
      });
      if (this.form.tags) {
        this.form.tags.forEach((e, index) => {
          payload.append(`LearningPostTagMappings[${index}].id`, e.value);
        });
      }
      payload.append('lessonModuleId', this.form.module?.value);
      payload.append('lessonLevelId', this.form.level?.value);
      payload.append('content', this.form.content ?? '');
      payload.append('enableLink', this.form.enableLink ?? false);
      payload.append('partnerName', this.form.partnerName);
      try {
        if (this.type == 'edit') {
          payload.append('id', this.form.id);
          await this.$api.put('LearningPost/Input', payload);
        } else {
          await this.$api.post('LearningPost/Input', payload);
        }
        this.$bvModal.hide('article-modal');
        this.$emit('loadData');
        this.$toastr.s({
          title: 'Thành công !',
          msg: this.type == 'edit' ? 'Cập nhật thành công' : 'Tao thành công!',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    resetModal() {
      this.$emit('update:id', null);
      this.form = {};
      this.position = null;
      this.file = null;
      this.preview = null;
      this.filePartner = null;
      this.previewPartner = null;
      this.fileBanner = null;
      this.previewBanner = null;
      this.content = null;
    },

    handleCancel() {
      this.resetModal();
      this.$bvModal.hide('article-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.image-wrapper {
  width: 150px;
  height: 150px;
  background-color: #f3f6f9;
  box-shadow: 0px 4px 4px 0px #000000 10%;
  position: relative;
  margin: auto;
}
.icon-edit {
  z-index: 100000;
  position: absolute;
  top: -30px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
</style>
